.toggle {
    cursor: pointer;
    /*display: inline-block;*/
    margin-right: 20px;
}
.mobile-top-nav{
    display: none;
}
.mobile-nav{
    display: none;
}

.toggle-switch {
    /*display: inline-block;*/
    background: #ccc;
    border-radius: 16px;
    width: 58px;
    height: 32px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
}
.toggle-switch:before, .toggle-switch:after {
    content: "";
}
.toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.toggle-checkbox:checked + .toggle-switch {
    background: #56c080;
}
.toggle-checkbox:checked + .toggle-switch:before {
    left: 30px;
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

.toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
}

.portal-container{
    display: flex;
}
.panel-cont{
    width: 100%;
}
.nav-container{
    width: 22%;
    height: 100dvh;
    background: #f5f5f5;
    /*display: none;*/

}
nav{
    width: 0;
    /*min-height: 100vh;*/
    background: #f5f5f5;
    transform: translateX(-100%);
    transition: transform 1.8s, visibility 0.001s;
    visibility: hidden;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
/*.nav-links{*/
/*    background: green;*/
/*}*/
.top-nav{
    display: flex;
    flex-direction: column;
}
.bottom-nav{
    display: flex;
    /*margin-top: 270px;*/
    margin-top: 0;
    /*justify-content: space-around;*/
    align-items: center;
    margin-bottom: 30px;
    justify-content: space-around;
}
.bottom-nav img{
    cursor: pointer;
    width: 40px;

}
.logo{
    width: 80%;
}
.menu-toggle {
    border: none;
    background: #f5f5f5;
    cursor: pointer;
    position: relative;
    width: 50px;
}
.menu-toggle svg{
    /*position: absolute;*/
    /*top: 250px;*/
    /*left: 10px;*/
}
nav .panel-btn{
    background-color: #f5f5f5;
    color: black;
    font-size: medium;
    font-weight: 400;
    padding: 10px;
    width: 94%;
    margin: 20px auto 20px 10px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    border: none;
    cursor: pointer;
    transition: background-color 0.8s ease;
}
nav {
    transform: translateX(0);
    visibility: visible;
    width: 18%;
    /*height: 100px;*/

}
nav .activate{
    background-color:#34A74D ;
    color: white;
}

/*@media screen and (min-width: 767px) and (max-width: 1024px) {*/

/*    nav.open {*/
/*        transform: translateX(0);*/
/*        visibility: visible;*/
/*        width: 30%;*/

/*    }*/
/*    .logo{*/
/*        width: 100%;*/
/*    }*/

/*}*/

@media only screen and (max-width: 768px){
    .nav-container{
        display: none;

    }
    .mobile-nav{
        position: fixed;
        top: 0;
        left: -100%; /* Off-screen by default */
        width: 70%; /* Adjust as needed */
        height: 100%;
        background-color: #fff; /* Mobile Nav Background */
        transition: left 0.3s ease; /* Smooth transition */
        z-index: 1000; /* Ensure it's above other content */
    }
    .open-nav {
        left: 0;
    }
    .top-nav-mobile {
        padding: 20px;
    }
    .mobile-top-nav{
        display: flex;
        justify-content:space-between;
        background: black;
        align-items: center;
        padding: 10px;
        color: white;
    }
    .logo-top-nav{
      width: 200px;
    }
    .mobile-top-nav button {
        border: none;
        background: black;
    }
    .mobile-top-nav svg{
        color: white;
    }
    .mobile-nav{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100dvh;
        width: 60%;
    }
    .nav-links-mobile {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .top-nav-mobile{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .nav-links-mobile .panel-btn-mobile{
        background-color: #f5f5f5;
        color: black;
        font-size: medium;
        font-weight: 400;
        padding: 10px;
        width: 94%;
        margin: 20px auto 20px 10px;
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        border: none;
        cursor: pointer;
        transition: background-color 0.8s ease;
    }
    .nav-links-mobile .activate{
        background-color:#34A74D ;
        color: white;
    }


}
