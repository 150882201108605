.booking-container{
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    width: 100%;
    /*margin-top: 50px;*/
}

.date-picker-container {
    display: flex;
    align-items: center;
    padding: 15px 0 0 15px;
}

.date-picker-container label {
    margin-right: 10px;
    font-size: 16px;
    color: #333;
}

.date-picker-container input[type="date"] {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.date-picker-container input[type="date"]:focus {
    outline: none;
    border-color: #0b0b0b;
}

.date-picker-container button {
    margin-left: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    background-color: #0b0b0b;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.date-picker-container button:hover {
    background-color: #0b0b0b;
}


.court-buttons{
    margin-top: 100px;
}
.court-buttons button{
    color: white;
    padding: 10px 25px;
    cursor: pointer;
    margin: auto 15px;
    border: none;
    border-radius: 10px;
    font-weight: 600;
}
.manual-booking-container{
    max-width: 700px;
}
.table-head{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
  
}
.table-head h2{
    font-size: larger;
}
.table-head button{
    background-color: white;
    height: 40px;
    padding: 5px;
    font-weight: 600;
    border: #f5f5f5 solid ;
    width: 100px;
    border-radius: 8px;
    cursor: pointer;
}


table{
    width: 100%;
}
 th{
font-weight: 400;
color: grey;
}

tr{
   
    padding-bottom: 10px;
    padding-top: 10px;
    
    
}
tr{
    border-bottom: 2px solid #191818;
}
td,th{
    
   padding: 10px;
    
}
.paid-booking {
    color: green;
    border-radius: 9px;
}

.unpaid-booking {
    color: red;
    border-radius: 9px;

}

.time-slot-buttons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}
.date-time-booking-court-btn{
    margin: 8px 8px 8px 0px;
    padding: 4px 1px;
    border: 2px solid #74AB3D;
    border-radius: 6px ;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    color:  #74AB3D;
    background-color: #fff;
    width:160px;
    transition: background-color 0.3s ease, color 0.3s ease;
}
.date-time-booking-court-btn.selected {
    background-color: #70A936;
    color: #FFFFFF;
}

/*.date-time-booking-court-btn:hover {*/
/*    background-color: #70a936;*/
/*    color: #fff;*/
/*}*/
.booking-btn{
    background: #34A74D;
    font-weight: 600;
    border:none;
    padding: 10px 40px;
    color: white;
    border-radius: 10px;
    font-size: medium;
    cursor: pointer;
    margin:38px auto 10px auto;
}




/* You might need to adjust these styles to better fit your UI */
@media only screen and (max-width: 768px){

    .manual-booking-container{
        max-width: 300px;
    }

    td,th{
        padding: 10px;
        font-size: small;
    }
    /*.date-time-booking-court-btn:hover {*/
    /*    !*color: #fff;*!*/
    /*    !*background-color: #74ab3d;*!*/
    /*    transition: none;*/
    /*}*/



}
