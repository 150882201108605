* { box-sizing: border-box; }
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&family=Ubuntu:wght@300;400;500;700&display=swap');



*{
  font-family: "Montserrat", sans-serif;
}

.container {
  display: flex;
  height: 100vh;
}

.left {
  overflow: hidden;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  justify-content: center;
  animation-name: left;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 1s;
  align-items: center;
  
}

.right {
  flex: 1;
  background-color: black;
  transition: 1s;
  background-image: url(../assets/gearUp.jpeg);
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
  
}



.header > h2 {
  margin: 0;
  color: #34A74D;
}

.header > h4 {
  margin-top: 10px;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0,0,0,.4);
}

.form {
  max-width: 80%;
  display: flex;
  flex-direction: column;
}

.form > p {
  text-align: right;
}

.form > p > a {
  color: #000;
  font-size: 14px;
}

.form-field {
  height: 46px;
  width: 326px;
  padding-left: 20px ;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  outline: 0;
  transition: .2s;
  margin-top: 20px;
  
}

.form-field:focus {
  border-color: #0f7ef1;
}

.form > button {
  padding: 12px 10px;
  border: 0;
  background: linear-gradient(to right, #2DE4AB 0%,#01081B 100%); 
  border-radius: 3px;
  margin-top: 10px;
  color: #fff;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.animation {
  animation-name: move;
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-delay: 2s;
}

.a1 {
  animation-delay: 2s;
}

.a2 {
  animation-delay: 2.1s;
}

.a3 {
  animation-delay: 2.2s;
}

.a4 {
  animation-delay: 2.3s;
}

.a5 {
  animation-delay: 2.4s;
}

.a6 {
  animation-delay: 2.5s;
}

@keyframes move {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes left {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
    padding: 20px 40px;
    width: 790px;
  }
}


@media only screen and (max-width: 768px){

  .form-field {
    width: 275px;

  }
  .form {
    max-width: 100%;
  }
}





