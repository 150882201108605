.App {
  text-align: center;
}
input,
.filter-container-1,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

 div._3n {
   cursor: pointer;
   margin: 0px 20px;
 }
 div._3n.or.FC {
  background: #0b0b0b;
 }
 /*div._1g{*/
 /*    background: #0b0b0b;*/
 /*    */
 /*}*/
div.FC ._1g {
    background-color: #0b0b0b;
    color: #fff;
}