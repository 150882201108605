.form-container{
  justify-content: center;
  align-items: center;
  height: auto;
  width:100%;
  margin: 40px auto;
  padding:30px ;
  /* box-shadow: 11px 12px 13px 12px rgb(207, 207, 207); */
  /* background-color: #ededed; */
  font-family: 'Rubik', sans-serif;
  letter-spacing: 1px;
 
}
.input-fields{
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-gap: 20px; 
  justify-content: center;
}

.form-fields{ 
  text-align: left; 
  margin-left: 50px;
  margin-bottom: 5px; 
  display: flex;
  flex-direction: column;

  /* margin-right: 50px;
  display: flex;
  justify-content: space-between; 
  align-items: flex-start;  */
}

.form-fields > label{
  /* display: block; */
  padding-bottom: 5px;
  color: #000;
  font-size: 15px;
}
.form-fields > input {
  
  font-family: 'Rubik', sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  border: 2px solid #c4c2c2;
  border-radius: 3px;
  height: 40px;
  padding: 0 80px;
  margin: 4px;
  /* width:250px; */
  /* max-width: 100%; */
  outline: none;
}
.form-fields > textarea{
  outline: none;
  height: 140px;
  font-size: 14px;
  border-radius: 3px;
  border: 2px solid #c4c2c2;
  padding: 10px 20px;



}
.form-fields-courts input,
.select,
option{
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  border: 2px solid #c4c2c2;
  border-radius: 3px;
  width: auto;
  height: 40px;
  margin: 4px;
  margin-top: 7px;
  outline: none;
}

  input[type="text"],
  input[type="number"],
  input[type="time"] ,
   .select{
    padding-left: 20px;
    padding-right: 20px;
    display:inline-block;
    margin-left: 10px;
  }

  .remove-btn{
    color: #fff;
    background-color: #df0d22;
    border:none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    margin: 5px 5px 5px 15px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: 1px;
  }
  .form-container > button {
    font-family: 'Rubik', sans-serif;
    padding: 12px 10px;
    border: 0;
    background-color: #28a745;
    width: 20%;
    border-radius: 3px;
    margin-top: 80px;
    color: #fff;
    letter-spacing: 1px;
    cursor: pointer;
  }
  
  .button-add{
      background-color: #0b0b0b;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 3px 0px;
      cursor: pointer;
      margin-top: 20px;
      margin-left: 10px;
      font-family: 'Rubik', sans-serif;
      letter-spacing: 1px;
      width: 20%;
      font-weight: 600;
      font-size: larger;

  }
  .image-upload-container{
    width: 100%;
    margin-top: 50px;
  }
  .image-head{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-head h2{
    margin-right: 20px;
  }
  .futsal-image{
    width: 200px;
    height: 100px;

  }
  input[type="file"] {
    display: none;
  }
  .file-upload svg{
    color: #28a745;
  }
  .file-upload{
    margin-top: 120px;
    cursor: pointer;
  }
  .image-send-btn{
    border: none;
    background: none;
    cursor: pointer;
  }
  .image-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 15px auto;
  }
  .image-container button{
    color: #fff;
    background-color: #df0d22;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    margin: 5px 5px 5px 15px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: 1px;
  }





@media screen and (min-width: 767px) and (max-width: 1024px) {

  .form-container{
    padding:0 ;

  }
  .form-fields{
    text-align: left;
    margin-left: 15px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
  }

}

@media screen and (max-width: 768px) {

  .input-fields {
    display: grid;
    grid-template-columns: repeat(1, 0fr);
    grid-gap: 16px;
    justify-content: center;
  }
  .form-fields{
    margin-left: 0;
  }
  .form-container{
    justify-content: center;
    align-items: center;
    height: auto;
    width:100%;
    margin: 40px auto;
    padding:30px ;
    /* box-shadow: 11px 12px 13px 12px rgb(207, 207, 207); */
    /* background-color: #ededed; */
    font-family: 'Rubik', sans-serif;
    letter-spacing: 1px;

  }
  .form-container >button{
    width: 50%;
  }


}



