.home-container{
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    width: 100%;
}
.home-header{
    display: flex;
    justify-content: space-between;
    padding:0 20px;
}
.text-header{
    /* display: flex;
    flex-direction: column; */
    text-align: left;
}
.text-header h1{
    font-size: 1.9rem;
}
.home-body-bottom h1{
    font-size: 1.9rem;
}
.text-header p{
    color: grey;
    margin-top: -15px;
}
.details-card{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 30px;
}
.detail-card{
    width: 250px;
    height: auto;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    /* margin: 0 30px; */
    padding-left: 15px;
    /*margin: 20px 40px 40px auto;*/



}
.detail-card h4{
    font-weight: 500;
    text-align: start;
}
.detail-card h2{
    text-align: start;
    font-weight: 600;
    margin-top: -5px;
}
.detail-card p{
    text-align: start;
    color: grey;
    font-size: small;
    margin-top: -15px;
}
.home-body{
    width: 100%;
}
.home-body-bottom{
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.booking-list{
    /*width: 80%;*/
    width: 70%;
    height: auto;
    /*margin-top: 20px;*/
    align-items: center;
    margin: 40px auto 0 auto;
}

@media only screen and (max-width: 768px){

.details-card{
    /*flex-direction: column;*/
    align-items: center;
    margin-top: 0;
}
    .detail-card{
        width: 110px;
        margin: 20px auto;
        height: 140px;
    }

    .booking-list{
        width: 95%;
    }
    .detail-card h2{
        font-size: medium;
        margin-bottom: 20px;
    }
    .text-header h1{
        font-size: 1.4rem;
    }
    .home-body-bottom h1{
        font-size: 1.4rem;
        text-align: left;
    }
}