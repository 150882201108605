/* Style for the popup */
.popup-form {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-inner {
    background-color: white;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Box shadow for a bit of depth */
    max-width: 80%;
    width: 400px;

}
.popup-inner form{
}
/*.input-field{*/
/*    margin: 20px auto;*/
/*    display: flex;*/
/*}*/

/*!* Close button style *!*/
/*.popup-inner button {*/
/*    background-color: #ff4c4c;*/
/*    color: white;*/
/*    border: none;*/
/*    padding: 8px 16px;*/
/*    margin-bottom: 16px;*/
/*    cursor: pointer;*/
/*    border-radius: 4px;*/
/*}*/
/* CSS */
.input-field {
    margin-bottom: 15px;
}

.popup-inner label {
    display: block;
    font-weight: 400;
    margin: 10px auto;
}

.popup-inner input[type='text'],
.popup-inner input[type='number'] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
}

.popup-inner button {
    padding: 10px 20px;
    background-color: #34A74D;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.popup-inner button:hover {
    background-color: #34A74D;
}
